import request from '@/utils/request'

/**
 * 业务ID查询登记
 * @param {*} data
 * @returns
 */
export function findByBusinessId(data) {
  return request({
    url: "/pay-web-api/admin/pay/regist/record/findByBusinessId",
    method: 'POST',
    data,
  })
}

/**
 * 设置收款订单分账规则
 * @param {*} data
 * @returns
 */
export function setPaySplitAccountConfigAbout(data) {
  return request({
    url: '/order-web-api/admin/orderserviceproportion/setOrderProportion',
    method: 'POST',
    data,
  })
}

/**
 * 设置加购订单分账规则
 * @param {*} data
 * @returns
 */
export function setAdditionalSplitAccountConfigAbout(data) {
  return request({
    url: 'order-web-api/admin/orderserviceproportion/setAdditionProportion',
    method: 'POST',
    data,
  })
}

/**
 * 获取分账规则配置信息
 * @param data
 * @returns {AxiosPromise}
 */
export function getSplitAccountConfig(data) {
  return request({
    url: 'order-web-api/admin/orderserviceproportion/getProportion',
    method: 'POST',
    data,
  })
}

/**
 * 查询分账记录列表
 * @param data
 * @returns {AxiosPromise}
 */
export function getSplitAccountList(data) {
  return request({
    url: 'order-web-api/admin/orderserviceproportion/getList',
    method: 'POST',
    data,
  })
}

/**
 *  门店余额
 * @param {}} data
 * @returns
 */
export function storeBanlanceList(data) {
  return request({
    url: 'supplier-web-api/admin/storewallet/getList',
    method: 'POST',
    data,
  })
}
// 提现详情
export function withdrawDetail(data) {
  return request({
    url: '/supplier-web-api/admin/withdraw/queryById',
    method: 'POST',
    data,
  })
}
// 提现通过/驳回
export function audit(data) {
  return request({
    url: '/supplier-web-api/admin/withdraw/audit',
    method: 'POST',
    data,
  })
}
// 确认打款

export function withdrawPay(data) {
  return request({
    url: '/supplier-web-api/admin/withdraw/withdrawPay',
    method: 'POST',
    data,
  })
}
// 支付记录
export function paymentorderList(data) {
  return request({
    url: '/pay-web-api/admin/paymentorder/getList',
    method: 'POST',
    data,
  })
}
// 支付详情
export function getOrderDetail(data) {
  return request({
    url: '/pay-web-api/admin/paymentorder/getOrderDetail',
    method: 'POST',
    data,
  })
}
// 支付产品详情
export function getOrderProductList(data) {
  return request({
    url: '/order-web-api/admin/order/getOrderProductList',
    method: 'POST',
    data,
  })
}
// 退款申请记录
export function getRefundPgRecorList(data) {
  return request({
    url: '/order-web-api/admin/orderservice/getRefundPgRecorList',
    method: 'POST',
    data,
  })
}
// 退款申请记录详情
export function queryUserOrderRefund(data) {
  return request({
    url: '/order-web-api/admin/orderservice/getUserRefundDeatil',
    method: 'POST',
    data,
  })
}
// 退款
export function dealRefundRecord(data) {
  return request({
    url: '/order-web-api/admin/orderservice/dealRefundRecord',
    method: 'POST',
    data,
  })
}
// 上传
export function uploadImage(data) {
  return request({
    url: '/misc-web-api/common/file/uploadImage',
    method: 'POST',
    data,
  })
}

export function queryReport(data) {
  return request({
    url: '/pay-web-api/admin/paymentorder/queryReport',
    method: 'POST',
    data,
  })
}
export function getRefundStatistics(data) {
  return request({
    url: '/order-web-api/admin/orderservice/getRefundStatistics',
    method: 'POST',
    data,
  })
}
export function getCurrentRefundStatis(data) {
  return request({
    url: '/order-web-api/admin/orderservice/getCurrentRefundStatis',
    method: 'POST',
    data,
  })
}
export function getOrders(data) {
  return request({
    url: '/order-web-api/admin/order/getOrders',
    method: 'POST',
    data,
  })
}
export function getWithdrawOrderList(data) {
  return request({
    url: '/order-web-api/admin/orderserviceproportion/getWithdrawOrderList',
    method: 'POST',
    data,
  })
}
export function exportGetList(data) {
  return request({
    url: '/order-web-api/admin/orderserviceproportion/exportGetList',
    method: 'POST',
    data,
    responseType: 'blob',
  })
}

export function paymentorderExport(data) {
  return request({
    url: '/pay-web-api/admin/paymentorder/export',
    method: 'POST',
    data,
    responseType: 'blob',
  })
}

export function refundExport(data) {
  return request({
    url: '/order-web-api/admin/orderservice/refundExport',
    method: 'POST',
    data,
    responseType: 'blob',
  })
}

export function paymentRecordList(data) {
  return request({
    url: '/pay-web-api/admin/payment/order/record/pageList',
    method: 'POST',
    data,
  })
}

export function getProductBrand(data) {
  return request({
    url: '/product-web-api/api/productBrand/list',
    method: 'POST',
    data,
  })
}

export function findNotDisableList(data) {
  return request({
    url: '/misc-web-api/api/photo/city/findNotDisableList',
    method: 'POST',
    data,
  })
}

export default {}
